var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap-header"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('div', {
    staticClass: "row m-0 p-0"
  }, [!_vm.noback && !_vm.isConfirmOrder && !_vm.isBackToHome && !_vm.isBackToEvent ? _c('div', {
    staticClass: "col p-0 m-0"
  }, [_c('router-link', {
    staticClass: "text-black",
    attrs: {
      "to": _vm.url
    }
  }, [_c('img', {
    staticClass: "back-icon",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-arrow-small-left.png")
    }
  })])], 1) : _vm._e(), _vm.isBackToHome ? _c('div', {
    staticClass: "col p-0 m-0",
    on: {
      "click": _vm.backToHome
    }
  }, [_c('img', {
    staticClass: "back-icon",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-arrow-small-left.png")
    }
  })]) : _vm._e(), _vm.isConfirmOrder ? _c('div', {
    staticClass: "col p-0 m-0",
    on: {
      "click": function ($event) {
        return _vm.$bvModal.show('confirm-modal');
      }
    }
  }, [_c('img', {
    staticClass: "back-icon",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-arrow-small-left.png")
    }
  })]) : _vm._e(), _vm.isBackToEvent ? _c('div', {
    staticClass: "col p-0 m-0",
    on: {
      "click": _vm.backToEvent
    }
  }, [_c('img', {
    staticClass: "back-icon",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-arrow-small-left.png")
    }
  })]) : _vm._e(), _vm.isBackToEventWithActiveRoom ? _c('div', {
    staticClass: "col p-0 m-0",
    on: {
      "click": function ($event) {
        return _vm.$bvModal.show('confirm-modal-back');
      }
    }
  }, [_c('img', {
    staticClass: "back-icon",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-arrow-small-left.png")
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "col p-0 m-0"
  }, [_c('div', {
    staticClass: "me-0 mb-0 d-flex justify-content-end"
  }, [_c('button', {
    staticClass: "btn btn-primary rounded-button-sm me-2",
    on: {
      "click": function ($event) {
        return _vm.$router.push('/check-ticket');
      }
    }
  }, [_c('img', {
    staticClass: "ticket-icon",
    attrs: {
      "src": require("../assets/img/icon/fi-rr-ticket.png")
    }
  })])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }