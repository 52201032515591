import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import ChooseTicket from "../views/ChooseTicket";
import DetailOrder from "../views/DetailOrder";
import FormData from "../views/FormData";
import FormVisitor from "../views/FormVisitor";
import PaymentMethod from "../views/PaymentMethod";
import PaymentDetail from "../views/PaymentDetail";
import SuccessPayment from "../views/SuccessPayment";
import FailedPayment from "../views/FailedPayment";
import BusyServer from "../views/BusyServer";
import CheckTicket from "../views/CheckTicket";
import ETicket from "../views/ETicket";
import LoadStatus from "../views/LoadStatus";
import FaceRecognition from "../views/FaceRecognition";

import store from "../store/index";

const authenticate = (to, from, next) => {

    const token = localStorage.getItem("token");
    // console.log(token)
    // const token = 'ada';
    if (token) {
        next()
        return
    } else {
        next('/login')
    }
}

const checkSession = (to, from, next) => {
    const lastPathData = localStorage.getItem('lastPathData');
    if (lastPathData && lastPathData !== 'choose-ticket') {
        // const platform_key = localStorage.getItem('platform_key');
        // const token = localStorage.setItem('token', token);
        // if (platform_key == 'MGO_IOS' || platform_key == 'MGO_ANDROID') {
        //     var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        //     if (/android/i.test(userAgent)) {
        //         JSBridge.deleteToken(token); // eslint-disable-line
        //     } else {
        //         window.webkit.messageHandlers.deleteToken.postMessage(token);
        //     }
        // }
        localStorage.clear();
        // window.localStorage.clear();
    }

    next();
}


const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: "/event/:name",
            name: "ChooseTicket",
            component: ChooseTicket,
            beforeEnter: checkSession
        },
        {
            path: "/detail-order",
            name: "DetailOrder",
            component: DetailOrder
        },
        {
            path: "/form-data",
            name: "FormData",
            component: FormData,
        },
        {
            path: "/form-visitor",
            name: "FormVisitor",
            component: FormVisitor,
        },
        {
            path: "/payment-method",
            name: "PaymentMethod",
            component: PaymentMethod,
        },
        {
            path: "/payment-detail",
            name: "PaymentDetail",
            component: PaymentDetail,
        },
        {
            path: "/payment-success",
            name: "SuccessPayment",
            component: SuccessPayment,
        },
        {
            path: "/payment-failed",
            name: "FailedPayment",
            component: FailedPayment,
        },
        {
            path: "/busy-server",
            name: "BusyServer",
            component: BusyServer,
        },
        {
            path: "/check-ticket",
            name: "CheckTicket",
            component: CheckTicket,
        },
        {
            path: "/e-ticket",
            name: "ETicket",
            component: ETicket,
        },
        {
            path: "/upload-fr",
            name: "FaceRecognition",
            component: FaceRecognition,
        },
        {
            path: "/check-status/:auth_token",
            name: "CheckStatus",
            component: LoadStatus
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
});

export default router;