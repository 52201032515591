<template>
  <div class="wrap-header">
    <div class="title">
      <div class="row m-0 p-0">
        <div class="col p-0 m-0" v-if="!noback && !isConfirmOrder && !isBackToHome && !isBackToEvent">
          <router-link :to="url" class="text-black">
            <img src="../assets/img/icon/fi-rr-arrow-small-left.png" class="back-icon" />
          </router-link>
        </div>
        <div class="col p-0 m-0" v-if="isBackToHome" @click="backToHome">
          <img src="../assets/img/icon/fi-rr-arrow-small-left.png" class="back-icon" />
        </div>
        <div class="col p-0 m-0" v-if="isConfirmOrder" @click="$bvModal.show('confirm-modal')">
          <img src="../assets/img/icon/fi-rr-arrow-small-left.png" class="back-icon" />
        </div>
        <div class="col p-0 m-0" v-if="isBackToEvent" @click="backToEvent">
          <img src="../assets/img/icon/fi-rr-arrow-small-left.png" class="back-icon" />
        </div>
        <div class="col p-0 m-0" v-if="isBackToEventWithActiveRoom" @click="$bvModal.show('confirm-modal-back')">
          <img src="../assets/img/icon/fi-rr-arrow-small-left.png" class="back-icon" />
        </div>
        <div class="col p-0 m-0">
          <div class="me-0 mb-0 d-flex justify-content-end">
            <button class="btn btn-primary rounded-button-sm me-2" @click="$router.push('/check-ticket')"><img
                src="../assets/img/icon/fi-rr-ticket.png" class="ticket-icon" /></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "HeaderComp",
  props: {
    title: String,
    url: String,
    noback: Boolean,
    isConfirmOrder: Boolean,
    isBackToHome: Boolean,
    isBackToEvent: Boolean,
    isBackToEventWithActiveRoom: Boolean
  },
  methods: {
    backToHome() {
      try {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/android/i.test(userAgent)) {
          JSBridge.backToHome(); // eslint-disable-line
        } else {
          window.webkit.messageHandlers.backToHome.postMessage(userAgent);
        }
      } catch (error) {
        console.log(error)
      }
    },
    backToEvent() {
      let base_url_event = localStorage.getItem('base_url_event');
      window.location.href = base_url_event;
    },
  },
};
</script>

<style scoped>
.wrap-header {
  height: 56px;
  background: #fff;
  line-height: 56px;
  padding: 5vw 2vw;
}

.wrap-header .title {
  font-weight: bold;
  font-size: 16px;
}

.back-icon {
  width: 24px;
}

.ticket-icon {
  width: 10px;
  height: 10px;
}

a {
  text-decoration: none;
}

.close-bar {
  border: 1px solid #F2F5F7;
  border-radius: 100px;
  padding: 0px 8px;
}

.dots-icon {
  border-right: 1px solid #F2F5F7;
  font-size: 14px;
  width: 14px;
  height: 14px;
  min-width: 14px;
  padding-right: 6px;
}

.close-icon {
  font-size: 14px;
  width: 14px;
  height: 14px;
  min-width: 14px;
  padding-left: 6px;
}
</style>
